import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useImperativeHandle, useRef, } from 'react';
import styled from '@emotion/styled';
const WrapperContainer = styled.div(() => ({
    textAlign: 'center',
}));
const Input = styled.input(() => ({
    visibility: 'hidden',
    width: 0,
    height: 0,
}));
const FileInputWrapperComponent = ({ children, onUpload = undefined, disabled = undefined, }, ref) => {
    const inputRef = useRef(null);
    const handleFileUpload = (ev) => {
        var _a, _b;
        if (!disabled) {
            const appliedFile = (_b = (_a = ev.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
            if (appliedFile && onUpload) {
                onUpload(appliedFile);
            }
            if (inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) {
                inputRef.current.value = '';
            }
        }
    };
    const handleContainerClick = () => {
        if ((inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) && !disabled) {
            inputRef.current.click();
        }
    };
    const handleFileDrop = (ev) => {
        ev.preventDefault();
        if (onUpload) {
            onUpload(ev.dataTransfer.files[0]);
        }
    };
    useImperativeHandle(ref, () => ({
        click: handleContainerClick,
    }), []);
    return (_jsxs(WrapperContainer, { onDrop: handleFileDrop, children: [children, _jsx(Input, { type: 'file', onChange: handleFileUpload, ref: inputRef })] }));
};
/**
 * wrapper for components which fires file upload via ref api
 */
const FileInputWrapper = forwardRef(FileInputWrapperComponent);
export default FileInputWrapper;
