var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef, useMemo, } from 'react';
import { debounceWrapper } from '@utils/Helpers';
import SearchInput from '@shared/ui/inputs/SearchInput';
const DebounceInputComponent = (_a, ref) => {
    var { onChange, debounceDelay = 300, searchFn, menuItems = [], hideMenu = true } = _a, props = __rest(_a, ["onChange", "debounceDelay", "searchFn", "menuItems", "hideMenu"]);
    const onChangeDebounce = useMemo(() => debounceWrapper(searchFn, debounceDelay), [searchFn, debounceDelay]);
    const onChangeHandler = (event) => {
        if (onChange) {
            onChange(event);
        }
        onChangeDebounce(event);
    };
    return (_jsx(SearchInput
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { ref: ref, searchFn: searchFn, onChange: onChangeHandler, menuItems: menuItems, hideMenu: hideMenu })));
};
const DebounceInput = forwardRef(DebounceInputComponent);
export default DebounceInput;
